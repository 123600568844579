import { useStore } from "@/shared_lib/store/store";
import { cn } from "@repo/utils";
import Image from "next/image";
import { AmigoSymbol } from "./amigoSymbol";

interface HeadshotProps {
  className?: string;
}

export default function Headshot({ className }: HeadshotProps) {
  const orgData = useStore((store) => store.domainData);
  const { signupPageHeadshot } = orgData;

  const defaultClassName = "h-auto max-h-[370px] max-w-[277px] rounded-[35px] shadow-2xl";

  if (!signupPageHeadshot) {
    return <AmigoSymbol className={cn(defaultClassName, className)} />;
  }

  const src = `data:image/png;base64,${signupPageHeadshot}`;
  return (
    <Image
      className={cn(defaultClassName, className)}
      src={src}
      alt="Headshot"
      aria-label="Headshot"
      width={276}
      height={370}
    />
  );
}
