import { Typography } from "@/shared_components/typography";
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from "react";

export interface InputTextFieldProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  placeholder?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  value: string;
  label?: string;
  size?: "small" | "medium" | "large";
  className?: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
}

export function InputTextField({
  onChange,
  id,
  value,
  label,
  disabled,
  size,
  className,
  onKeyDown,
  placeholder,
  required,
  autoFocus,
  onBlur,
}: InputTextFieldProps) {
  const sizeClass = {
    small: "px-4 py-2 rounded-[12px]",
    medium: "px-4 py-3 rounded-[16px]",
    large: "px-4 py-4 rounded-[20px]",
  }[size || "medium"];

  const disabledClass = disabled
    ? "text-content-support border-border-components"
    : "bg-white border-border-components";

  return (
    <div className={`${className} flex flex-col`}>
      <label className="text-content-primary">
        <Typography weight="500" variant="small">
          {label}
        </Typography>
      </label>
      <input
        disabled={disabled}
        required={required}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder || ""}
        id={id}
        onChange={onChange}
        className={` ${sizeClass} shadow-button inline-flex items-center ${disabledClass} placeholder:text-content-placeholder focus:ring-content-placeholder justify-center gap-1 rounded-2xl border border-[#e9e9e9] bg-white text-base leading-none placeholder:text-base focus:ring-2`}
      />
    </div>
  );
}
